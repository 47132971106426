type TippyTopBannerProps = {
  tippyTopBanner: {
    markup: string
  }
}

import './styles/tippy-top-banner.scss';

export const TippyTopBanner = ({ tippyTopBanner }:TippyTopBannerProps) => (
  <section
    className="tippy-top-banner-section"
    data-section="tippy top banner"
    dangerouslySetInnerHTML={ { __html: tippyTopBanner.markup } }
  />
);